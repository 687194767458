import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';

const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));
const Desbloquear = lazy(() => import('../container/profile/authentication/overview/Desbloquear'));
const SolicitarCambiar = lazy(() => import('../container/profile/authentication/overview/SolicitarCambiarContrasenia'));
const Cambiar = lazy(() => import('../container/profile/authentication/overview/CambiarContrasenia'));

const NotFound = () => {
  return <Redirect to={`/`} />;
};

const FrontendRoutes = () => {
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route exact path={`/usuario/solicitar_desbloquear`} component={Desbloquear} />
        <Route exact path={`/usuario/solicitar_cambiar_contrasenia`} component={SolicitarCambiar} />
        <Route exact path={`/cambiar_contrasenia/:mensaje`} component={Cambiar} />

        <Route exact path={`/:mensaje`} component={Login} />
        <Route exact path={`/`} component={Login} />
        
        <Route exact path={`/:mensaje/:mensaje/*`} component={NotFound} />

      </Suspense>
    </Switch>
  );
};

export default AuthLayout(FrontendRoutes);
