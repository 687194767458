import actions from './actions';
import { API } from '../../config/api/Api';
import { notification } from 'antd'
//import { fijarAjustes } from '../ajustes/actionCreator';

const {
  loginBegin,
  loginSuccess,
  loginErr,
  checkPreviousLoginBegin,
  checkPreviousLoginSuccess,
  checkPreviousLoginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr
} = actions;

const login = (username, password) => {
  return async dispatch => {
    dispatch(loginBegin());
    API.login(username, password).then(response => {
      if (response.data.success) {
        dispatch(loginSuccess());
        //dispatch(fijarAjustes(response.data.data.ajusteTienda))
      } else {
        dispatch(loginErr(response.data.error.message));
      }
    }).catch(err => {
      dispatch(loginErr(err));
    })
  };
};

const checkPreviousLogin = () => {
  return async dispatch => {
    if (API.getAccessToken() != null) {
      dispatch(checkPreviousLoginBegin());
      API.freeRefreshRequest();
      API.getNewToken(false).then(response => {
        if (response.data.success) {
          dispatch(checkPreviousLoginSuccess());
        } else {
          dispatch(checkPreviousLoginErr(response.data.error.message));
          API.removeToken();
        }
      }).catch(err => {
        dispatch(checkPreviousLoginErr(err));
        API.removeToken();
      })
    }
  };
};

const logOut = () => {
  return async dispatch => {
    dispatch(logoutBegin());
    API.logout().then(response => {
      dispatch(logoutSuccess());
    }).catch(err => {
      dispatch(logoutErr(err));
    })
  };
};

const solicitarDesbloquear = (username) => {
  return async () => {
    //envía los datos
    // const data = new FormData();
    // data.append('data', JSON.stringify({usuario: username}));
    API.postDesbloquear(username).then(response =>
    {
      if (response.data.success)
      {
        notification.success({
          message: 'Si tu usuario se encuentra bloqueado y lo ingresaste correctamente, revisa tu correo para continuar con el desbloqueo',
          placement: 'bottomRight',
        });
      }
    });
  };
};

const solicitarCambiarContrasenia = (username) => {
  return async () => {
    //envía los datos
    // const data = new FormData();
    // data.append('data', JSON.stringify({usuario: username}));
    API.postSolicitarCambiar(username).then(response =>
    {
      if (response.data.success)
      {
        notification.success({
          message: 'Si tu usuario no se encuentra conectado y lo ingresaste correctamente, revisa tu correo para continuar con el proceso',
          placement: 'bottomRight',
        });
      }
    });
  };
};

const cambiarContrasenia = (password, password2, id, signature) => {
  return async () => {
    const data = new FormData();
    data.append('data', JSON.stringify({password: password, password2: password2, idUsuario: id, signature: signature}));
    API.postCambiar(data).then(response =>
    {
      if (response.data.success)
      {
        notification.success({
          message: 'Contraseña actualizada',
          placement: 'bottomRight',
        });
      }
    });
  };
};

export { login, checkPreviousLogin, logOut, solicitarDesbloquear, solicitarCambiarContrasenia, cambiarContrasenia };
